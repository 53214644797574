/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { Box, Flex, Image } from 'theme-ui';

import agw from '../assets/agw.jpg';
import hero from '../assets/agw-hero.jpg';

const AboutHero = () => {
  return (
    <Flex
      sx={{
        height: '600px',
        width: '100%',
        backgroundImage: `url(${hero})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        justifyContent: 'center',
        alignItems: 'center',
      }}></Flex>
  );
};

export default AboutHero;
