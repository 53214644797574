/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { graphql, Link } from 'gatsby';
import { Box, Flex, Text, Image } from 'theme-ui';

import Layout from '../components/Layout';
import AboutHero from '../components/AboutHero';
import CompanyStats from '../components/CompanyStats';
// import BioCard from '../components/BioCard';

const AGWPartners = ({ data }) => {
  const html = data.markdownRemark.html;
  return (
    <Layout pageTitle="About AGW Partners">
      <AboutHero></AboutHero>
      <Box
        sx={{
          display: ['block', 'flex', 'flex'],
          maxWidth: '1400px',
          margin: 'auto',
        }}
      >
        <Box
          sx={{
            width: ['100%', '100%', '100%'],
            maxWidth: '900px',
            margin: 'auto',
            paddingX: 2,
          }}
        >
          <Box
            sx={{
              padding: 3,
              paddingX: 4,
              fontSize: 2,
              lineHight: '1.75',
              position: 'relative',
              mt: 5,
              mb: 5,
            }}
          >
            <Image
              src={
                'https://agwpartners.com/wp-content/uploads/2020/04/AWGlogo-300x93.png'
              }
            ></Image>
            <Box
              className="agw-partners"
              dangerouslySetInnerHTML={{ __html: html }}
            ></Box>
          </Box>

          {/* <Text
            as='p'
            sx={{
              textAlign: 'center',
              marginBottom: [3, 4, 5],
              mt: [3, 4, 5],
            }}>
            For Leasing Information

          </Text> */}
        </Box>

        {/* <Box sx={{ width: ['100%', '100%', '35%'], paddingX: 2, paddingY: [6, 6, 0] }}>
          <Box
            sx={{
              margin: 'auto',
              padding: 2,
              paddingX: 2,
              paddingY: 4,
              borderRadius: '13px',
              border: '1px solid #eee',
              mt: '-6rem',
              background: '#fff',
              position: 'relative',
            }}>
            <BioCard></BioCard>
          </Box>
        </Box> */}
      </Box>
      <CompanyStats title="Company Stats" />
    </Layout>
  );
};

export default AGWPartners;

export const query = graphql`
  query MyQuery {
    markdownRemark {
      html
    }
  }
`;
